import React, { PureComponent } from "react";
import { RouteComponentProps } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import userService from "../../services/userService";
import authService from "../../services/authService";
import dbService from "../../services/dbService";
import Timeline from "./Timeline";
import orderUtils from "../../utils/orderUtils";
import SplashScreen from "../common/SplashScreen";
import { getCW } from "../../utils/helper";
import { getTopSpaceHeaderHeight } from "../../assets/js/topSpaceHeaderHeight";

interface TrackingData {
  state: string;
  targetDate: Date | null;
  timeline: Array<{ type: string; date: Date }>;
}

interface TrackingParams {
  trackingId: string;
}

interface TrackingProps extends WithTranslation, RouteComponentProps<{}, {}, TrackingParams> {}

interface TrackingState {
  trackingNumber: string;
  trackingData?: TrackingData;
  savedNumber: string;
  reloading: boolean;
  updateTime: number;
  errorMessage: string;
}

class Tracking extends PureComponent<TrackingProps, TrackingState> {
  constructor(props: TrackingProps) {
    super(props);
    this.state = {
      trackingNumber: "",
      trackingData: undefined,
      savedNumber: "",
      reloading: false,
      updateTime: Date.now(),
      errorMessage: "",
    };
  }

  async componentDidMount() {
    if (!userService.getUser()) {
      await authService.login();
    }
    this.forceUpdate();
    const query = new URLSearchParams(this.props.location.search);
    const trackingId = query.get("trackingId");
    if (trackingId && trackingId.trim() !== "") {
      this.setState({ trackingNumber: trackingId }, this.handleGo);
    }
  }

  handleTrackingNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ trackingNumber: e.target.value });
  };

  handleSubmit = async (e: any) => {
    e.preventDefault();
    await this.handleGo();
  };

  handleGo = async () => {
    const { t } = this.props;
    const { trackingNumber } = this.state;
    const result = await dbService.getTrackingInformation(trackingNumber);
    const trackingData = result.result;
    if (!trackingData) {
      let message = "";
      if (result.error) message = t("unknownError") + result.error;
      if (result.message) message = t(result.message);
      this.setState({ errorMessage: message, trackingData: undefined, trackingNumber: "", savedNumber: "" });
      return;
    }
    this.setState({
      trackingData: trackingData,
      savedNumber: trackingData ? trackingNumber : "",
      trackingNumber: "",
      updateTime: Date.now(),
      errorMessage: "",
    });
  };

  handleRefresh = async () => {
    const { t } = this.props;
    const { savedNumber } = this.state;
    this.setState({ reloading: true });
    const result = await dbService.getTrackingInformation(savedNumber);
    const trackingData = result.result;
    if (!trackingData) {
      let message = "";
      if (result.error) message = t("unknownError") + result.error;
      if (result.message) message = t(result.message);
      this.setState({ errorMessage: message });
      return;
    }
    setTimeout(
      () =>
        this.setState({
          trackingData,
          reloading: false,
          savedNumber: trackingData ? savedNumber : "",
          updateTime: Date.now(),
          errorMessage: "",
        }),
      1000
    );
  };

  getTargetWeek = (targetDate: Date | string) => {
    if (typeof targetDate === "string") return targetDate.split("-")[0];
    return getCW(targetDate as Date);
  };

  getTargetYear = (targetDate: Date | string) => {
    if (typeof targetDate === "string") return targetDate.split("-")[1];
    return targetDate.getFullYear();
  };

  render() {
    const { t } = this.props;
    const { trackingNumber, trackingData, reloading, savedNumber, updateTime, errorMessage } = this.state;
    return (
      <section className="section"  style={{marginTop: getTopSpaceHeaderHeight()}}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-lg-12 col-md-12 margin-5-rem-bottom">
              <h3 className="alt-font font-weight-600 text-extra-dark-gray w-100 text-center">
                Tracking
                <br />
              </h3>
            </div>
          </div>
          {!userService.getUser() ? (
            <div className="card container gutter-b no-border">
              <div className="card-body">
                <SplashScreen />
              </div>
            </div>
          ) : (
            <>
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col" />
                  <div className="col-12 col-md-8 col-lg-5">
                    <div className="input-group">
                      <input
                        className="form-control form-control-lg"
                        placeholder={t("trackingNumber")}
                        value={trackingNumber}
                        onChange={this.handleTrackingNumber}
                      />
                      <div className="input-group-append">
                        <button
                          type={"submit"}
                          className="btn btn-fancy btn-dark-gray pl-5 pr-5"
                          onClick={this.handleGo}
                        >
                          Go!
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col" />
                </div>
              </form>
              {errorMessage && (
                <div className="row justify-content-center">
                  <span className="text-danger font-size-lg">{errorMessage}</span>
                </div>
              )}
              {!trackingData ? (
                <div className="row justify-content-center mt-5">
                  <span style={{ fontSize: "18px" }}>{t("trackingNotice")}</span>
                </div>
              ) : (
                <div className="row mt-5 justify-content-center">
                  <div className="card card-shadowless no-border">
                    <div className="card-header bg-white">
                      <div className="card no-border">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-10">
                              <span className="font-weight-bolder text-dark" style={{ fontSize: "24px" }}>
                                {t("trackingCurrentState")}
                                <span className="text-green">{t(trackingData!.state)}</span>
                              </span>
                              <br />
                              <span className="font-weight-bolder text-dark" style={{ fontSize: "24px" }}>
                                {t("trackingNextState")}
                                <span className="text-muted">
                                  {t(orderUtils.getNextOrderState(trackingData!.state) || "-")}
                                </span>
                              </span>
                              {trackingData.targetDate && (
                                <>
                                  <br />
                                  <span className="font-weight-bolder text-dark" style={{ fontSize: "24px" }}>
                                    {t("trackingPlannedDelivery")}
                                    <span className="text-green">{this.getTargetWeek(trackingData.targetDate)}</span>
                                    {` (${this.getTargetYear(trackingData.targetDate)})`}
                                  </span>
                                </>
                              )}
                            </div>
                            <div className="col-2 my-auto">
                              <button
                                className={
                                  "btn btn-icon " + ((reloading || savedNumber === "") && "btn-custom-disabled")
                                }
                                onClick={this.handleRefresh}
                                disabled={reloading || savedNumber === ""}
                              >
                                <i className={"fas fa-sync icon-2x text-black " + (reloading ? "icon-spinner" : "")} />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body ">
                      {trackingData!.timeline ? (
                        <Timeline timeline={trackingData?.timeline!} updateTime={updateTime} />
                      ) : (
                        <div className="card no-border">
                          <div className="card-body">
                            <span className="font-size-lg ">{t("noTimelineData")}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <span className="scroll-top-arrow">
          <i className="feather icon-feather-arrow-up text-extra-dark-gray" />
        </span>
      </section>
    );
  }
}

export default withTranslation("order")(Tracking);
