import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { getTopSpaceHeaderHeight } from "../assets/js/topSpaceHeaderHeight";

class LocationText1 extends Component {
  state = {};
  render() {
    const { t } = this.props;
    return (
      <section>
        <div className="container"  style={{marginTop: getTopSpaceHeaderHeight()}}>
          <div className="row align-items-center justify-content-center">
            <div
              className="col-12 col-lg-12 col-md-12  wow animate__fadeInLeft"
              data-wow-delay="0.5s"
              style={{
                visibility: "visible",
                animationDelay: "0.5s",
                animationName: "fadeInLeft",
              }}
            >
              <h5 className="alt-font font-weight-600 text-extra-dark-gray w-95">
                {t("title66")}
                <br></br>
                {t("title67")}
                <br></br>
                <br></br>
                {t("title68")}
              </h5>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation("general")(LocationText1);
