import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import { withTranslation } from "react-i18next";

class Header extends Component {
  state = { showLanguageSelection: false, isDark: false, isTransparent: false };

  showLanguageSelection = () => {
    this.setState({ showLanguageSelection: true });
  };

  hideLanguageSelection = () => {
    setTimeout(() => {
      this.setState({ showLanguageSelection: false });
    }, 250);
  };

  setLanguage = (lng) => {
    localStorage.setItem("i18nextLng", lng);
    window.location.reload();
  };

  getLocalStorageLocales = () => {
    return localStorage.getItem("i18nextLng").split(/-|_/)[0];
  };

  getBrowserLocales = (options = {}) => {
    const defaultOptions = { languageCodeOnly: false };
    const opt = { ...defaultOptions, ...options };
    const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;
    if (!browserLocales) return "en";
    return opt.languageCodeOnly ? browserLocales[0].split(/-|_/)[0] : browserLocales[0].trim();
  };

  setHeaderState = (isDark, isTransparent) => {
    this.setState({ isDark, isTransparent });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  throttle(func, limit) {
    let inThrottle;
    return function (...args) {
      if (!inThrottle) {
        func.apply(this, args);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), limit);
      }
    };
  }


  handleScroll = () => {
    requestAnimationFrame(() => {
      this.checkSections([
        { id: "map", stateKey: "isDark" },
        { id: "quality", stateKey: "isDark" },
        { id: "portfoliohero", stateKey: "isTransparent" },
        { id: "portfoliodetail", stateKey: "isTransparent" }
      ]);
    });
  };

  previousActiveSection = null;
  scrollThreshold = 100;

  checkSections = (sections) => {
    const headerElement = document.querySelector(".navbar");
    if (!headerElement) return;

    const headerBottom = headerElement.getBoundingClientRect().bottom;
    let newState = { isDark: false, isTransparent: false };
    let activeSection = null;

    sections.forEach(({ id, stateKey }) => {
      const section = document.getElementById(id);
      if (!section) return;

      const { top: sectionTop, bottom: sectionBottom } = section.getBoundingClientRect();
      const isInsideSection =
        headerBottom >= sectionTop - this.scrollThreshold &&
        headerBottom < sectionBottom + this.scrollThreshold;


      if (isInsideSection) {
        newState[stateKey] = true;
        activeSection = stateKey;
      }
    });

    if (!activeSection && this.previousActiveSection) {
      newState[this.previousActiveSection] = true;
    }

    if (JSON.stringify(newState) !== JSON.stringify(this.state)) {
      this.setState(newState);
      this.previousActiveSection = activeSection;
    }
  };




  render() {
    const { t } = this.props;
    const { isDark, isTransparent } = this.state;

    return (
      // https://videos.ctfassets.net/vkizfq3pbtzc/2NnzkZ32NC1T6HqOe6R3MR/e466592d9e71428d184842b7b5003783/Trade_Republic_-_2021_-_30sec_loop_-_V12B_Final_LowQ.mp4
      <React.Fragment>
        <header className={"header-with-topbar "}>
          <div
            className={
              "top-bar " +
              (isDark ? "top-bar-super-dark" : "") +
              " bg-light-gray d-none d-md-inline-block padding-35px-lr md-no-padding-lr pr-0"
            }
            style={{ top: "0px" }}
          >
            <div className="container-fluid nav-header-container pr-0">
              <div className="d-flex flex-wrap align-items-center">
                <div className="col-12 text-center text-left col-sm-auto mr-auto pl-lg-0 ">
                  <p className="text-medium m-0 dnb-1331">
                    <span
                      className={
                        "font-weight-500 text-extra-dark-gray " + (isDark ? "top-bar-super-dark-text-white" : "")
                      }
                    >
                      {t("title19")}
                    </span>{" "}
                    {t("title20")}{" "}
                    <a
                      href="/configurator"
                      style={{
                        color: "black !important",
                        cursor: "pointer",
                      }}
                      className={"text-black header-link2 " + (isDark ? "top-bar-super-dark-text-white" : "text-extra-dark-gray")}
                    >
                      <u style={{color: "#000000 !important"}}>{t("title21")}</u>
                    </a>{" "}
                    {t("title22")}
                  </p>
                </div>
                <div className="col-auto d-none d-sm-block text-right px-lg-0 font-size-0">
                  <div className="top-bar-contact">
                    <span
                      className={
                        "top-bar-contact-list border-none md-no-padding-right " +
                        (isDark ? "top-bar-super-dark-text-white" : "")
                      }
                    >
                      <i
                        className={
                          "feather icon-feather-phone-call icon-extra-small  " +
                          (isDark ? "top-bar-super-dark-text-white" : "text-extra-dark-gray")
                        }
                      />
                      +49 (0)209 51956620
                    </span>
                    <span className="top-bar-contact-list border-none md-no-padding-right">
                      <i
                        className={
                          "feather icon-feather-mail icon-extra-small  " +
                          (isDark ? "top-bar-super-dark-text-white" : "text-extra-dark-gray")
                        }
                      />
                      <a
                        href="mailto:info@private-label-factory.com"
                        className={"header-link " + (isDark ? "top-bar-super-dark-text-white" : "text-extra-dark-gray")}
                      >
                        info@private-label-factory.com
                      </a>
                    </span>
                    <span
                      className={
                        "top-bar-contact-list d-none d-lg-inline-block border-none " +
                        (isDark ? "top-bar-super-dark-text-white" : "")
                      }
                    >
                      <i
                        className={
                          "feather icon-feather-map-pin icon-extra-small " +
                          (isDark ? "top-bar-super-dark-text-white" : "text-extra-dark-gray")
                        }
                      />
                      Recklinghausen, Germany
                    </span>
                  </div>
                </div>{" "}
                <div className="col-auto d-none d-sm-block text-right px-0 font-size-0 cursor-pointer ">
                  <a
                    className={
                      "top-bar-contact float-right d-block " + (isDark ? "top-bar-less-super-dark" : "color-wellma")
                    }
                    href="https://www.wellma.com/"
                  >
                    <span className="top-bar-contact-list border-none no-padding-right text-white ml-2 mr-4">
                      <span
                        className={"font-size-sm " + (isDark ? "top-bar-super-dark-text-white" : "text-dark-charcoal")}
                      >
                        part of
                      </span>

                      <img
                        src={
                          "https://uploads-ssl.webflow.com/63233543f43d5bfcfcc248c2/6391c000f7dc63636833f2f1_wellma-logo-light.svg"
                        }
                        style={{ display: "inline", width: 80, marginLeft: 10 }}
                      />
                    </span>
                  </a>
                </div>{" "}
              </div>
            </div>
          </div>
          {/* <nav className="navbar navbar-expand-lg navbar-dark bg-transparent header-light fixed-top header-reverse-scroll"> */}

          <nav
            className={
              "navbar navbar-light " +
              (isDark ? "navbar-dark" : isTransparent ? "navbar-blurry" : "") +
              " navbar-expand-lg top-spae bg-white header-transparent fixed-top navbar-boxed"
            }
            style={{ top: "48px" }}
          >
            <div className="container container-xxl nav-header-container">
              <div className="col-6 col-lg-2 mr-auto pl-lg-0">
                <a className="navbar-brand" href="/">
                  {/* <img src="images/plf-logo-4.png" data-at2x="images/plf-logo-4.png" className="logo" alt="" /> */}

                  <ReactSVG src="images/logo-dark.svg" style={{ width: "160px" }} />

                  {/*  <img src="images/logo-plf-2.png" data-at2x="images/logo-plf-2.png" className="logo" alt="" /> */}
                  {/* <img src="images/plf-sm-2.png" data-at2x="images/plf-sm-2.png" className="alt-logo" alt="" /> */}
                  {/*   <img src="images/logo-gradient-tan-geraldine.png" data-at2x="images/logo-gradient-tan-geraldine@2x.png" className="alt-logo" alt="" />
                <img src="images/logo-gradient-tan-geraldine.png" data-at2x="images/logo-gradient-tan-geraldine@2x.png" className="mobile-logo" alt="" /> */}
                </a>
              </div>
              <div className="col-auto menu-order px-lg-0">
                <button
                  className="navbar-toggler float-right"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-line" />
                  <span className="navbar-toggler-line" />
                  <span className="navbar-toggler-line" />
                  <span className="navbar-toggler-line" />
                </button>
                <div className=" collapse navbar-collapse justify-content-center" id="navbarNav">
                  <ul className="navbar-nav alt-font">
                    {/* <li className="nav-item dropdown megamenu active">
                    <a href="/" className="nav-link futura2">Start</a>
                   </li>
                   <li className="nav-item dropdown megamenu">
                    <a href="/" className="nav-link futura2">Über Uns</a>
                   </li>
                   <li className="nav-item dropdown megamenu">
                    <a href="/" className="nav-link futura2">Warum Wir</a>
                   </li>
                   <li className="nav-item dropdown megamenu">
                    <a href="/" className="nav-link futura2">Portfolio</a>
                   </li>
                   <li className="nav-item dropdown megamenu">
                    <a href="/" className="nav-link futura2">Produktion</a>
                   </li>
                   <li className="nav-item dropdown megamenu">
                    <a href="/" className="nav-link futura2">Zertifizierungen</a>
                   </li>
                  <li className="nav-item dropdown megamenu">
                    <a href="/" className="nav-link futura2">Konfigurator</a>
                   </li> */}

                    <li className="nav-item dropdown megamenu">
                      <a href="/" className="nav-link futura2">
                        {t("title23")}
                      </a>
                    </li>
                    {/*  <li className="nav-item dropdown megamenu">
                      <a href="about-us" className="nav-link futura2">
                        Warum Wir
                      </a>
                    </li> */}
                    <li className="nav-item dropdown megamenu">
                      <a href="/team" className="nav-link futura2">
                        {t("title24")}
                      </a>
                    </li>
                    <li className="nav-item dropdown megamenu">
                      <a href="/production" className="nav-link futura2">
                        {t("title25")}
                      </a>
                    </li>
                    <li className="nav-item dropdown megamenu">
                      <a href="/certifications" className="nav-link futura2">
                        {t("title26")}
                      </a>
                    </li>
                    <li className="nav-item dropdown megamenu">
                      <a href="/portfolio" className="nav-link futura2">
                        {t("title27")}
                      </a>
                    </li>

                    <li className="nav-item dropdown megamenu">
                      <a href="/configurator" className="nav-link futura2">
                        {t("title28")}
                      </a>
                    </li>
                    <li className="nav-item dropdown megamenu">
                      <a href="/tracking" className="nav-link futura2">
                        {t("title29")}
                      </a>
                    </li>
                    <li
                      className="nav-item dropdown simple-dropdown"
                      onMouseEnter={() => this.showLanguageSelection()}
                      onMouseLeave={() => this.hideLanguageSelection()}
                    >
                      <a href="javascript:void(0);" className="nav-link">
                        <img
                          src={"/images/countries_128/" + (this.getLocalStorageLocales() || "en") + ".png"}
                          className="fab"
                          style={{ width: 16 }}
                          data-toggle="dropdown"
                          aria-hidden="true"
                          alt=""
                        ></img>
                      </a>

                      <i className="fa fa-angle-down dropdown-toggle" data-toggle="dropdown" aria-hidden="true" />
                      <ul
                        className={"dropdown-menu " + (this.state.showLanguageSelection ? "d-block" : "d-none")}
                        role="menu"
                        onMouseEnter={() => this.showLanguageSelection()}
                        onMouseLeave={() => this.hideLanguageSelection()}
                      >
                        <li className="dropdown">
                          <a href="javascript:void(0);" onClick={() => this.setLanguage("de")}>
                            <img
                              src={"/images/countries_128/de.png"}
                              className="fab mr-2"
                              style={{ width: 16 }}
                              data-toggle="dropdown"
                              aria-hidden="true"
                              alt=""
                            ></img>
                            Deutsch
                          </a>
                        </li>
                        <li className="dropdown">
                          <a href="javascript:void(0);" onClick={() => this.setLanguage("en")}>
                            <img
                              src={"/images/countries_128/en.png"}
                              className="fab mr-2"
                              style={{ width: 16 }}
                              data-toggle="dropdown"
                              aria-hidden="true"
                              alt=""
                            ></img>
                            Englisch
                          </a>
                        </li>
                        <li className="dropdown">
                          <a href="javascript:void(0);" onClick={() => this.setLanguage("fr")}>
                            <img
                              src={"/images/countries_128/fr.png"}
                              className="fab mr-2"
                              style={{ width: 16 }}
                              data-toggle="dropdown"
                              aria-hidden="true"
                              alt=""
                            ></img>
                            Französisch
                          </a>
                        </li>
                        <li className="dropdown">
                          <a href="javascript:void(0);" onClick={() => this.setLanguage("es")}>
                            <img
                              src={"/images/countries_128/es.png"}
                              className="fab mr-2"
                              style={{ width: 16 }}
                              data-toggle="dropdown"
                              aria-hidden="true"
                              alt=""
                            ></img>
                            Spanisch
                          </a>
                        </li>
                        <li className="dropdown">
                          <a href="javascript:void(0);" onClick={() => this.setLanguage("it")}>
                            <img
                              src={"/images/countries_128/it.png"}
                              className="fab mr-2"
                              style={{ width: 16 }}
                              data-toggle="dropdown"
                              aria-hidden="true"
                              alt=""
                            ></img>
                            Italienisch
                          </a>
                        </li>
                        <li className="dropdown">
                          <a href="javascript:void(0);" onClick={() => this.setLanguage("pt")}>
                            <img
                              src={"/images/countries_128/pt.png"}
                              className="fab mr-2"
                              style={{ width: 16 }}
                              data-toggle="dropdown"
                              aria-hidden="true"
                              alt=""
                            ></img>
                            Portugiesisch
                          </a>
                        </li>
                        <li className="dropdown">
                          <a href="javascript:void(0);" onClick={() => this.setLanguage("pl")}>
                            <img
                              src={"/images/countries_128/pl.png"}
                              className="fab mr-2"
                              style={{ width: 16 }}
                              data-toggle="dropdown"
                              aria-hidden="true"
                              alt=""
                            ></img>
                            Polnisch
                          </a>
                        </li>
                        <li className="dropdown">
                          <a href="javascript:void(0);" onClick={() => this.setLanguage("cs")}>
                            <img
                              src={"/images/countries_128/cs.png"}
                              className="fab mr-2"
                              style={{ width: 16 }}
                              data-toggle="dropdown"
                              aria-hidden="true"
                              alt=""
                            ></img>
                            Tschechisch
                          </a>
                        </li>
                        <li className="dropdown">
                          <a href="javascript:void(0);" onClick={() => this.setLanguage("sv")}>
                            <img
                              src={"/images/countries_128/sv.png"}
                              className="fab mr-2"
                              style={{ width: 16 }}
                              data-toggle="dropdown"
                              aria-hidden="true"
                              alt=""
                            ></img>
                            Schwedisch
                          </a>
                        </li>
                        <li className="dropdown">
                          <a href="javascript:void(0);" onClick={() => this.setLanguage("tr")}>
                            <img
                              src={"/images/countries_128/tr.png"}
                              className="fab mr-2"
                              style={{ width: 16 }}
                              data-toggle="dropdown"
                              aria-hidden="true"
                              alt=""
                            ></img>
                            Türkisch
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-auto text-right pr-0 font-size-0">
                <div className="header-social-icon d-none d-sm-inline-block">
                  <a href="https://www.xing.com/pages/privatelabelfactorydeutschlandgmbh" target="_blank">
                    <i className="fab fa-xing text-black" />
                  </a>
                  <a href="https://www.linkedin.com/company/37827432/" target="_blank">
                    <i className="fab fa-linkedin text-black" />
                  </a>
                </div>
              </div>
            </div>
          </nav>
        </header>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(Header);
