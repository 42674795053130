const menuBreakPoint = 991;

/****** Get window width ******/
function getWindowWidth() {
  return window.innerWidth;
}

/****** Get window height ******/
function getWindowHeight() {
  return window.innerHeight;
}

/****** Get top space header height ******/
export function getTopSpaceHeaderHeight() {
  let topSpaceHeaderHeight = 0;

  const navbar = document.querySelector("header nav.navbar");
  if (navbar) {
    topSpaceHeaderHeight += navbar.offsetHeight;
  }

  const topBar = document.querySelector(".top-bar");
  if (topBar) {
    topSpaceHeaderHeight += topBar.offsetHeight;
  }

  const sidebarNav = document.querySelector(".sidebar-nav-action");
  if (sidebarNav && getWindowWidth() <= menuBreakPoint) {
    topSpaceHeaderHeight += sidebarNav.offsetHeight;
  }

  const sideMenuHeader = document.querySelector(".side-menu-header");
  if (sideMenuHeader && getWindowWidth() <= menuBreakPoint) {
    topSpaceHeaderHeight += sideMenuHeader.offsetHeight;
  }

  return topSpaceHeaderHeight;
}