import _ from "lodash";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { RouteComponentProps } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import { Tab } from "react-bootstrap";
import { CapsulesDocument } from "../../model/capsules.types";
import { TabletsDocument } from "../../model/tablets.types";
import { CustomPackagingsDocument, SelectedPackagingsDocument } from "./CustomTypes";
import SplashScreen from "../common/SplashScreen";
import dbService from "../../services/dbService";
import ConfiguratorTabs, {
  CAPSULES_TAB,
  CUSTOM_TAB,
  LIQUID_TAB,
  POWDER_TAB,
  SOFTGELS_TAB,
  TABLETS_TAB,
} from "./ConfiguratorTabs";
import CapsuleSelection from "./CapsuleSelection";
import RecipeSelection from "./RecipeSelection";
import PackagingConfiguration from "./PackagingConfiguration";
import TabletSelection from "./TabletSelection";
import PurchasedSelection from "./PurchasedSelection";
import configuratorUtils from "../../utils/configuratorUtils";
import ConfigurationConfirmation from "./ConfigurationConfirmation";
import CustomPreferences from "./CustomPreferences";
import ConfiguratorUtils from "./ConfiguratorHelpers";
import ConfiguratorHelpers from "./ConfiguratorHelpers";
import SimpleConfirmationModal from "../common/SimpleConfirmationModal";
import { ColorsDocument } from "../../model/colors.types";

interface ConfiguratorProps extends WithTranslation, RouteComponentProps<{}, {}, {}> {}

export interface ConfiguratorState {
  activeTab: string;
  capsules: Array<CapsulesDocument>;
  tablets: Array<TabletsDocument>;
  calculations: Array<string>;
  commodities: Array<any>;
  recipe: Array<any>;
  recipeVolume: any;
  selectedPackaging: Array<SelectedPackagingsDocument>;
  packaging: Array<CustomPackagingsDocument>;
  preferences: any;
  colors: Array<ColorsDocument>;
  contactDetails: {
    phone: string;
    email: string;
    companyName: string;
    street: string;
    zip: string;
    city: string;
    country: string;
  };
  couponCode: string;
}

class Configurator extends Component<ConfiguratorProps, ConfiguratorState> {
  _isMounted = false;
  constructor(props: ConfiguratorProps) {
    super(props);
    const activeTab = localStorage.getItem("configuratorActiveTab") || CAPSULES_TAB;
    this.state = {
      activeTab: activeTab,
      capsules: [],
      tablets: [],
      commodities: [],
      recipe: [],
      selectedPackaging: [],
      packaging: [],
      recipeVolume: { value: 0, noDefault: true },
      preferences: ConfiguratorHelpers.getDefaultPreferences(activeTab),
      calculations: ["1000", "2500", "5000"],
      colors: [],
      contactDetails: { phone: "", email: "", companyName: "", street: "", zip: "", city: "", country: "" },
      couponCode: "",
    };
  }

  /**
   * Builds and return an object that represents the state in its default form (right after cdm)
   * @param tab tab to get default preferences for
   * @return an object with default state values
   */
  getDefaultState = (tab: string) => {
    const selectedPackaging: Array<SelectedPackagingsDocument> = [];
    const recipe: Array<any> = [];
    const calculations = ["1000", "2500", "5000"];
    const { capsules, tablets } = this.state;
    const prefs: any = ConfiguratorHelpers.getDefaultPreferences(tab);
    if (capsules.length > 0) prefs.selectedCapsule = capsules[0];
    if (tablets.length > 0) prefs.selectedTablet = tablets[0];
    return {
      recipeVolume: { value: 0, noDefault: true },
      selectedPackaging: selectedPackaging,
      recipe: recipe,
      calculations: calculations,
      preferences: prefs,
      contactDetails: { phone: "", email: "", companyName: "", street: "", zip: "", city: "", country: "" },
      couponCode: "",
    };
  };

  handleTabChange = (tab: string) => {
    const state = this.getDefaultState(tab);
    // Set Tab and State
    localStorage.setItem("configuratorActiveTab", tab);
    this.setState({ activeTab: tab, ...state });
  };

  handleNewConfiguration = () => {
    const state = this.getDefaultState(CAPSULES_TAB);
    // Set base url
    this.props.history.replace("/configurator");
    // Set state back to default
    localStorage.setItem("configuratorActiveTab", CAPSULES_TAB);
    this.setState({ activeTab: CAPSULES_TAB, ...state });
  };

  handlePreferencesChange = (path: string, item: any) => {
    const preferences = { ...this.state.preferences };
    _.set(preferences, path, item);
    this.setState({ preferences });
  };

  handleContactDetailsChange = (path: string, item: any) => {
    const contactDetails = { ...this.state.contactDetails };
    _.set(contactDetails, path, item);
    this.setState({ contactDetails });
  };

  handleCouponCodeChange = (value: string) => this.setState({ couponCode: value });

  handleRecipeAdd = (commodity: any, amount: number) => {
    // Early abort if someone somehow finds a way to smuggle invalid numbers to the function
    if (amount <= 0) return;
    const { activeTab } = this.state;
    let recipeVolume = { ...this.state.recipeVolume };
    const preferences = { ...this.state.preferences };
    const recipe = [...this.state.recipe];
    let tempCom = { ...commodity };
    // Populate recipe items with additional identifier and amount
    // Additional id is for deletion
    tempCom.ident = configuratorUtils.generateInternalId(9);
    tempCom.amount = amount;
    // If custom and softgel tab just allow 1 commodity in recipe
    if ([CUSTOM_TAB, SOFTGELS_TAB].includes(activeTab)) {
      this.setState({ recipe: [tempCom] });
    } else {
      // compute volume
      const [commodityVolume, noDefault] = configuratorUtils.getVolume(tempCom);
      recipeVolume = { value: recipeVolume.value + commodityVolume, noDefault: recipeVolume.noDefault && noDefault };
      recipe.push(tempCom);
      let state: any = { recipe, recipeVolume };
      if ([POWDER_TAB, LIQUID_TAB].includes(activeTab)) {
        preferences.amountPerUnit = (+preferences.amountPerUnit + amount).toString();
        state.preferences = preferences;
      }
      this.setState(state);
    }
  };

  handleRecipeAmountChange = (id: string, amount: number) => {
    // Deep copy recipe
    const recipe = _.cloneDeep(this.state.recipe);
    const commodity = recipe.find((com) => com._id.toString() === id);
    commodity.amount = amount;
    // Compute new volume
    const recipeVolume = configuratorUtils.getRecipeVolume(recipe);
    let state: any = { recipe, recipeVolume };
    this.setState(state);
  };

  handleRecipeDelete = (ident: number) => {
    const { activeTab, recipe } = this.state;
    const preferences = { ...this.state.preferences };
    const newRecipe = recipe.filter((com) => com.ident !== ident);
    const newState: any = { recipe: newRecipe };
    if (![CUSTOM_TAB, SOFTGELS_TAB].includes(activeTab)) {
      // Calculate recipe volume
      const newRecipeVolume = configuratorUtils.getRecipeVolume(newRecipe);
      newState.recipeVolume = newRecipeVolume;
    }
    if ([POWDER_TAB, LIQUID_TAB].includes(activeTab)) {
      const commodity = recipe.find((com) => com.ident === ident);
      preferences.amountPerUnit = (+preferences.amountPerUnit - commodity.amount).toString();
      newState.preferences = preferences;
    }
    this.setState(newState);
  };

  /**
   * Select the package
   * @param packaging the packaging object
   */
  handlePackagingSelect = (packaging: CustomPackagingsDocument) => {
    const selectedPackaging = [...this.state.selectedPackaging];
    // Copy/Change type
    const selPackaging = { ...packaging } as SelectedPackagingsDocument;
    // Remove recommended flag if necessary
    if ("recommended" in selPackaging) _.unset(selPackaging, "recommended");
    // Set amount and add to selectedPackaging
    selPackaging.amount = 1;
    selectedPackaging.push(selPackaging);
    this.setState({
      selectedPackaging: selectedPackaging,
    });
  };

  handlePackagingOverwrite = (packs: Array<SelectedPackagingsDocument>) => {
    this.setState({ selectedPackaging: packs });
  };

  handlePackagingAmount = (pack: SelectedPackagingsDocument, add: boolean) => {
    // Dont go above 99
    if (add && pack.amount === 99) return;
    // Copy packaging parameter and selectedPackaging array
    const selPackaging = { ...pack };
    let selectedPackaging: Array<SelectedPackagingsDocument> = [...this.state.selectedPackaging];

    const index = selectedPackaging.indexOf(pack);
    if (add) selPackaging.amount = selPackaging.amount! + 1;
    else selPackaging.amount = selPackaging.amount! - 1;
    if (selPackaging.amount === 0) {
      // Remove amount, delete from selected and add to packaging
      selectedPackaging.splice(index, 1);
    } else {
      selectedPackaging[index] = selPackaging;
    }
    this.setState({ selectedPackaging });
  };

  handleCalculationChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const calculations = [...this.state.calculations];
    calculations[index] = Number(parseInt(e.target.value) || "0").toString();
    this.setState({ calculations });
  };

  getSelectedPreference = () => {
    const { preferences, activeTab } = this.state;
    switch (activeTab) {
      case CAPSULES_TAB:
        return _.omit(preferences.selectedCapsule, "suppliers");
      case TABLETS_TAB:
        return _.omit(preferences.selectedTablet, "suppliers");
      default:
        return "";
    }
  };

  getAmountPerUnit = () => {
    const { activeTab } = this.state;
    switch (activeTab) {
      case CAPSULES_TAB:
      case TABLETS_TAB:
      case CUSTOM_TAB:
      case SOFTGELS_TAB:
        return parseInt(this.state.preferences.amountPerUnit);
      case POWDER_TAB:
      case LIQUID_TAB:
        // sum of all recipe amounts
        return this.state.recipe.reduce((a, b) => a + +b.amount, 0);
    }
  };

  /**
   * Handle configuration save or cache
   */
  handleSendRequest = async () => {
    // Abort if request is incomplete and it should not be cached
    if (!this.isRequestComplete) return;
    const { preferences, selectedPackaging, recipe, activeTab, calculations, contactDetails, couponCode } = this.state;
    const { t } = this.props;
    // Add simple data, state, createdOn, identifier and priority is added in the backend!!
    let order: any = {
      title: preferences.title,
      subtitle: preferences.subtitle,
      settings: {
        type: configuratorUtils.getTypeForTab(activeTab),
        perUnit: this.getAmountPerUnit(),
        selected: this.getSelectedPreference(),
      },
      packaging: selectedPackaging,
      recipe: recipe,
      calculations: calculations,
      contact: contactDetails,
      coupon: couponCode,
    };

    try {
      let res = await dbService.createNewRequest(order);

      // Handle result success/error
      if (!res) toast.error(this.props.t("error:unexpectedError"));
      else {
        // @ts-ignore
        window.gtag("event", "conversion", { send_to: "AW-1002235909/mgm1CPfoivACEIXQ890D" });
        toast.success(this.props.t("configurator:createSuccess"));
      }
    } catch (e) {
      toast.error(t("error:unexpectedError"));
    }
  };

  /**
   * Check if all required data is available
   * @returns: true if all data is available, false if data is incomplete
   */
  isRequestComplete = () => {
    const { preferences, selectedPackaging, recipe, activeTab } = this.state;
    let preferenceComplete: boolean = true;
    // Check complete preferences
    switch (activeTab) {
      case CAPSULES_TAB:
        preferenceComplete = preferenceComplete && preferences.selectedCapsule && preferences.amountPerUnit > 0;
        break;
      case TABLETS_TAB:
        preferenceComplete = preferenceComplete && preferences.selectedTablet && preferences.amountPerUnit > 0;
        break;
      case CUSTOM_TAB:
      case SOFTGELS_TAB:
        preferenceComplete = preferenceComplete && preferences.amountPerUnit > 0;
        break;
      default:
        preferenceComplete = true;
    }
    // Check complete packaging
    let packagingComplete: boolean = true;
    // Custom products are excluded from packaging validation
    if (activeTab !== CUSTOM_TAB) {
      // prettier-ignore
      const torsoTypes = configuratorUtils.getTorsoTypeForProduct(activeTab, preferences);
      // prettier-ignore
      const torsoPackaging = selectedPackaging.find(entry => torsoTypes.includes(entry.packaging_type))
      packagingComplete = packagingComplete && !!torsoPackaging;
      if (torsoPackaging) {
        // prettier-ignore
        const mandatoryPackaging = configuratorUtils.getMandatoryTypesForTorso(torsoPackaging.packaging_type);
        const mandatoryFulfilled = configuratorUtils.isMandatorySelected(mandatoryPackaging, selectedPackaging);
        packagingComplete = packagingComplete && mandatoryFulfilled;
      }
    }
    return preferenceComplete && packagingComplete && recipe.length > 0;
  };

  /**
   * Check if preferences are initialized
   */
  isPreferencesInitialized = () => {
    switch (this.state.activeTab) {
      case CAPSULES_TAB:
        return this.state.preferences.selectedCapsule;
      case TABLETS_TAB:
        return this.state.preferences.selectedTablet;
      default:
        return this.state.commodities.length > 0;
    }
  };

  shouldComponentUpdate(
    nextProps: Readonly<ConfiguratorProps>,
    nextState: Readonly<ConfiguratorState>,
    nextContext: any
  ): boolean {
    return this.state !== nextState;
  }

  async componentDidMount() {
    this._isMounted = true;
    const stateObject: ConfiguratorState = await ConfiguratorUtils.loadConfiguratorState(this.state.activeTab);
    if (
      this._isMounted &&
      stateObject.packaging.length > 0 &&
      stateObject.capsules.length > 0 &&
      stateObject.commodities.length > 0
    ) {
      if (_.has(stateObject, "activeTab")) localStorage.setItem("configuratorActiveTab", stateObject.activeTab);
      this.setState(stateObject);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { t } = this.props;
    const {
      activeTab,
      recipe,
      packaging,
      capsules,
      calculations,
      colors,
      tablets,
      commodities,
      selectedPackaging,
      preferences,
      recipeVolume,
      contactDetails,
      couponCode,
    } = this.state;

    const packagingConfigurationProperties: any = {
      activeTab: activeTab,
      preferences: preferences,
      packaging: packaging,
      selectedPackaging: selectedPackaging,
      onPackagingSelect: this.handlePackagingSelect,
      onPackagingAmount: this.handlePackagingAmount,
      onPackagingOverwrite: this.handlePackagingOverwrite,
    };
    if ([LIQUID_TAB, POWDER_TAB].includes(activeTab)) packagingConfigurationProperties.recipeVolume = recipeVolume;
    return (
      <Tab.Container id="tabContainer" activeKey={activeTab}>
        <div
          className="card container gutter-b"
          style={{
            /* boxShadow: "0px 0px 30px 0px rgba(82, 63, 105, 0.05)", */
            border: 0,
            minHeight: "100%",
            backgroundColor: "#fafafa",
            padding: 20,
            borderRadius: 5,
          }}
        >
          {packaging.length === 0 || capsules.length === 0 || commodities.length === 0 || tablets.length === 0 ? (
            <div className="align-middle my-auto">
              <SplashScreen additionalSVGStyle={{ height: "50vh", width: "60px" }} />
            </div>
          ) : (
            <>
              <div
                className="card-header card-header-tabs-line px-0"
                style={{ backgroundColor: "#fafafa", borderBottom: "none" }}
              >
                <div></div>
                <div className="d-flex justify-content-between flex-wrap mt-1 mb-3">
                  <div className="d-flex mr-3">
                    <span className="text-dark-75 text-hover-primary font-size-h1 font-weight-bold mr-3">
                      {t("newCalculation")}
                      <br></br>
                      <small>{t("configureSomething")}</small>
                    </span>
                  </div>
                  <ButtonBar t={t} onNewConfiguration={this.handleNewConfiguration} />
                </div>
                <div className="card-toolbar">
                  <ConfiguratorTabs activeTab={activeTab} onTabChange={this.handleTabChange} t={t} />
                </div>
              </div>
              <div className="card-body px-0 mt-5">
                <Tab.Content>
                  {activeTab === CAPSULES_TAB && (
                    <Tab.Pane eventKey={CAPSULES_TAB} transition={false} style={{ backgroundColor: "#fafafa" }}>
                      <CapsuleSelection
                        capsules={capsules}
                        t={t}
                        onPreferenceChange={this.handlePreferencesChange}
                        preferences={preferences}
                      />
                      <div className=" my-5" />
                      <RecipeSelection
                        activeTab={activeTab}
                        onRecipeAdd={this.handleRecipeAdd}
                        onRecipeDelete={this.handleRecipeDelete}
                        onRecipeAmountChange={this.handleRecipeAmountChange}
                        commodities={commodities}
                        colors={colors}
                        recipe={recipe}
                        t={t}
                        currentVolume={recipeVolume.value}
                        maxVolume={+preferences.selectedCapsule.capsule_volume}
                        showInfo={!recipeVolume.noDefault}
                      />
                      <div className=" my-5" />
                      <PackagingConfiguration {...packagingConfigurationProperties} />
                    </Tab.Pane>
                  )}
                  {activeTab === TABLETS_TAB && (
                    <Tab.Pane eventKey={TABLETS_TAB} transition={false} style={{ backgroundColor: "#fafafa" }}>
                      <TabletSelection
                        tablets={tablets}
                        t={t}
                        onPreferenceChange={this.handlePreferencesChange}
                        preferences={preferences}
                      />
                      <div className=" my-5" />
                      <RecipeSelection
                        activeTab={activeTab}
                        onRecipeAdd={this.handleRecipeAdd}
                        onRecipeDelete={this.handleRecipeDelete}
                        onRecipeAmountChange={this.handleRecipeAmountChange}
                        commodities={commodities}
                        colors={colors}
                        recipe={recipe}
                        t={t}
                        currentVolume={recipeVolume.value}
                        maxVolume={+preferences.selectedTablet.volume}
                        showInfo={!recipeVolume.noDefault}
                      />
                      <div className=" my-5" />
                      <PackagingConfiguration {...packagingConfigurationProperties} />
                    </Tab.Pane>
                  )}
                  {activeTab === POWDER_TAB && (
                    <Tab.Pane eventKey={POWDER_TAB} transition={false}>
                      <RecipeSelection
                        activeTab={activeTab}
                        onRecipeAdd={this.handleRecipeAdd}
                        onRecipeDelete={this.handleRecipeDelete}
                        onRecipeAmountChange={this.handleRecipeAmountChange}
                        commodities={commodities}
                        colors={colors}
                        recipe={recipe}
                        t={t}
                        currentVolume={recipeVolume.value}
                        showInfo={!recipeVolume.noDefault}
                      />
                      <div className=" my-5" />
                      <PackagingConfiguration {...packagingConfigurationProperties} />
                    </Tab.Pane>
                  )}
                  {activeTab === LIQUID_TAB && (
                    <Tab.Pane eventKey={LIQUID_TAB} transition={false}>
                      <RecipeSelection
                        activeTab={activeTab}
                        onRecipeAdd={this.handleRecipeAdd}
                        onRecipeDelete={this.handleRecipeDelete}
                        onRecipeAmountChange={this.handleRecipeAmountChange}
                        commodities={commodities}
                        colors={colors}
                        recipe={recipe}
                        t={t}
                        currentVolume={recipeVolume.value}
                        showInfo={!recipeVolume.noDefault}
                      />
                      <div className=" my-5" />
                      <PackagingConfiguration {...packagingConfigurationProperties} />
                    </Tab.Pane>
                  )}
                  {/*activeTab === CUSTOM_TAB && (
                    <Tab.Pane eventKey={CUSTOM_TAB} transition={false} style={{ backgroundColor: "#fafafa" }}>
                      <CustomPreferences
                        t={t}
                        preferences={preferences}
                        onPreferenceChange={this.handlePreferencesChange}
                      />
                      <div className=" my-5" />
                      <PurchasedSelection
                        activeTab={activeTab}
                        onRecipeAdd={this.handleRecipeAdd}
                        onRecipeDelete={this.handleRecipeDelete}
                        commodities={commodities}
                        recipe={recipe}
                        t={t}
                      />
                      <div className=" my-5" />
                      <PackagingConfiguration {...packagingConfigurationProperties} />
                    </Tab.Pane>
                  )*/}
                  {activeTab === SOFTGELS_TAB && (
                    <Tab.Pane eventKey={SOFTGELS_TAB} transition={false} style={{ backgroundColor: "#fafafa" }}>
                      <CustomPreferences
                        t={t}
                        preferences={preferences}
                        onPreferenceChange={this.handlePreferencesChange}
                      />
                      <div className=" my-5" />
                      <PurchasedSelection
                        activeTab={activeTab}
                        onRecipeAdd={this.handleRecipeAdd}
                        onRecipeDelete={this.handleRecipeDelete}
                        commodities={commodities}
                        recipe={recipe}
                        t={t}
                      />
                      <div className=" my-5" />
                      <PackagingConfiguration {...packagingConfigurationProperties} />
                    </Tab.Pane>
                  )}
                </Tab.Content>
              </div>
              {this.isPreferencesInitialized() && (
                <div className="card-footer" style={{ backgroundColor: "#fafafa" }}>
                  <div className="d-flex justify-content-between flex-wrap mt-1">
                    <div className="d-flex mr-3" />
                    <div className="my-lg-0 my-3">
                      <ConfigurationConfirmation
                        activeTab={activeTab}
                        calculations={calculations}
                        preferences={preferences}
                        selectedPackaging={selectedPackaging}
                        recipe={recipe}
                        contactDetails={contactDetails}
                        couponCode={couponCode}
                        requestComplete={this.isRequestComplete()}
                        onSendRequest={this.handleSendRequest}
                        onPreferenceChange={this.handlePreferencesChange}
                        onCalculationChange={this.handleCalculationChange}
                        onContactDetailsChange={this.handleContactDetailsChange}
                        onCouponCodeChange={this.handleCouponCodeChange}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Tab.Container>
    );
  }
}

export default withTranslation(["configurator", "error"])(Configurator);

interface ButtonBarProps {
  t: (key: string, options?: any) => string;
  onNewConfiguration: any;
}

const ButtonBar: React.FunctionComponent<ButtonBarProps> = (props) => {
  const { t, onNewConfiguration } = props;
  return (
    <div className="my-lg-0 mr-0 mr-md-3">
      <SimpleConfirmationModal.SimpleConfirmationModalButton
        buttonClasses={"btn btn-dark btn-sm font-weight-bold font-size-sm mr-2 mb-2"}
        buttonText={t("newConfiguration")}
        modalTitle={t("newConfiguration")}
        modalDescription={t("newConfigurationInfo")}
        confirmButtonText={"Ok"}
        cancelButtonText={t("cancel")}
        onConfirm={onNewConfiguration}
        buttonId="newConfiguration"
      />
    </div>
  );
};
