import React, { Component, useEffect, useState, useRef } from "react";
import Cert from "../sections/cert";
import { withTranslation } from "react-i18next";
import Bubbles from "../sections/bubbles";
import { Tilt } from "react-tilt";
import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";
import Coins2 from "../sections/coins2";
import Stats from "../sections/stats";
import { getTopSpaceHeaderHeight } from "../assets/js/topSpaceHeaderHeight";


const Portfolio = ({ t }) => {
  const [maxHeight, setMaxHeight] = useState(0);
  const cardRefs = useRef([]);


  const imageData = [
    {
      src: "images/softgel-preview.jpg",
      title: t("portfolio-1"),
      description: t("portfolio-2"),
      badges: [t("portfolio-3"), t("portfolio-4"), t("portfolio-5"), t("portfolio-6"), t("portfolio-7")]
    },
    {
      src: "images/tablets-preview.jpg",
      title: t("portfolio-8"),
      description: t("portfolio-9"),
      badges: [t("portfolio-10"), t("portfolio-11"), t("portfolio-12"), t("portfolio-13"), t("portfolio-14")]
    },
    {
      src: "images/capsules-preview.jpg",
      title: t("portfolio-15"),
      description: t("portfolio-16"),
      badges: [t("portfolio-17"), t("portfolio-18"), t("portfolio-19"), t("portfolio-20"), t("portfolio-21"), t("portfolio-22"), t("portfolio-23"), t("portfolio-24")]
    },
    {
      src: "images/powder-preview.jpg",
      title: t("portfolio-25"),
      description: t("portfolio-26"),
      badges: [t("portfolio-27"), t("portfolio-28"), t("portfolio-29"), t("portfolio-30")]
    },
    {
      src: "images/liquids-preview.jpg",
      title: t("portfolio-31"),
      description: t("portfolio-32"),
      badges: [t("portfolio-33"), t("portfolio-34"), t("portfolio-35")]
    },
    {
      src: "images/gummies-preview.jpg",
      title: t("portfolio-36"),
      description: t("portfolio-37"),
      badges: [t("portfolio-38"), t("portfolio-39"), t("portfolio-40"), t("portfolio-41")]
    },
    {
      src: "images/brause-preview.jpg",
      title: t("portfolio-42"),
      description: t("portfolio-43"),
      badges: [t("portfolio-44"), t("portfolio-45"), t("portfolio-46"), t("portfolio-47")]
    },
    {
      src: "images/liquidcapsules-preview.jpg",
      title: t("portfolio-48"),
      description: t("portfolio-49"),
      badges: [t("portfolio-50"), t("portfolio-51"), t("portfolio-52")]
    },
    {
      src: "images/gels-preview.jpg",
      title: t("portfolio-53"),
      description: t("portfolio-54"),
      badges: [t("portfolio-55"), t("portfolio-56"), t("portfolio-57"), t("portfolio-58")]
    },
    {
      src: "images/shot-preview.jpg",
      title: t("portfolio-59"),
      description: t("portfolio-60"),
      badges: [t("portfolio-61"), t("portfolio-62"), t("portfolio-63")]
    },
    {
      src: "images/proteinshake-preview.jpg",
      title: t("portfolio-64"),
      description: t("portfolio-65"),
      badges: [t("portfolio-66"), t("portfolio-67"), t("portfolio-68")]
    },
    {
      src: "images/proteinbar-preview.jpg",
      title: t("portfolio-69"),
      description: t("portfolio-70"),
      badges: [t("portfolio-71"), t("portfolio-72"), t("portfolio-73"), t("portfolio-74")]
    }
  ];

  useEffect(() => {
    const updateHeight = () => {
      const heights = cardRefs.current.map((card) => card?.offsetHeight || 0);
      setMaxHeight(Math.max(...heights));
    };

    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => window.removeEventListener("resize", updateHeight);
  }, [imageData]);

    const items = ["ISO 22000", "IFS", "FDA", "Organic", "HACCP", "GMP"];
    const extendedItems = [...items, ...items, ...items];

      useEffect(() => {
        const handleScroll = () => {
          const detailSection = document.getElementById("portfoliohero");
          if (detailSection) {
            let scrollPosition = window.scrollY;
            detailSection.style.transform = `translateY(${Math.max( scrollPosition * 0.2)}px)`;
          }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
      }, []);

    return (
      <React.Fragment>
        <section
          className="big-section bg-light-gray overlap-height overflow-visible md-no-overlap-section big-capsule-section "
          id="portfoliohero"
        >
          <div className="container ">
            <div
              className="col-12 col-lg-12 d-flex flex-row margin-8-rem-bottom sm-d-block">
              <h6
                className="text-extra-dark-gray alt-font  letter-spacing-1px wow animate__fadeIn pt-8 col-12 col-md-8 "

              >
                <h3 className="alt-font font-weight-600 text-extra-dark-gray pt-20 pb-10">Das Portfolio.</h3>
                Wir bieten ein breites Spektrum an Darreichungsformen für Nahrungsergänzungsmittel und unterstützen Sie bei der Entwicklung nahezu jeder Produktidee.<br/>Gemeinsam realisieren wir maßgeschneiderte Lösungen – von klassischen Konzepten bis hin zu innovativen Trends.
              </h6>
              <span className="col-auto w-40px h-1px bg-dark-gray opacity-3" />
            </div>
          </div>
        </section>
        <section className="pt-lg-0 pb-0 overflow-visible" id="portfoliodetail">
          <div className="container" style={{ marginTop: "-150px" }}>
            <div className="row g-5">
              {imageData.map((item, index) => (
                <div key={index} className="col-12 col-md-6 col-lg-4">
                  <div className="mb-5" style={{ backgroundColor: "#f5f5f5", borderRadius: 5 }}>
                    <div
                      style={{
                        backgroundImage: `url(${item.src})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        borderRadius: "4px",
                        overflow: "hidden",
                        minHeight: "415px",
                        position: "relative",
                      }}
                    ></div>
                    <div
                      ref={(el) => (cardRefs.current[index] = el)}
                      className="p-3"
                      style={{ lineHeight: "1.5rem", minHeight: maxHeight }}
                    >
                      <span className="text-extra-medium text-extra-dark-gray font-weight-600 d-block">
                        {t(item.title)}
                      </span>
                      <span className="text-dark">{t(item.description)}</span>
                      <div className="d-block mt-2">
                        {item.badges.map((badge, badgeIndex) => (
                          <div key={badgeIndex} className="badge badge-dark m-1" style={{ backgroundColor: "#b0b0b0" }}>
                            {badge}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section className="bg-light-gray">
          <div className="container" style={{ paddingTop: 100 }}>
            <div className="row align-items-center justify-content-center">
              <div
                className="col-12 col-lg-12 col-md-12 md-margin-7-rem-bottom wow animate__fadeInLeft"
                data-wow-delay="0.5s"
                style={{ visibility: "visible", animationDelay: "0.5s", animationName: "fadeInLeft" }}
              >
                <h5 className="alt-font font-weight-600 text-extra-dark-gray w-95">
                  Unsere modernen Prozesse ermöglichen bei jedem Projekt herausragende Qualität und größtmögliche Flexibilität.<br/>Profitieren Sie von unserer Expertise, um gemeinsam innovative Produkte zu schaffen, die am Markt herausstechen.

                </h5>
              </div>
            </div>
          </div>
        </section>

        <div className="text-white" style={{ marginTop: 100 }}>
          <div className="container container2 cover-background border-radius-10px padding-5-half-rem-all">
            <video loop="true" autoPlay="true" controls="" muted className="html-video2 ">
              <source type="video/mp4" src="images/softgelproduction.mp4" />
            </video>

            <div className="row overlap-gap-section border-radius-10pxl">
              <h3 className="alt-font font-weight-600">Rohstoffe, die überzeugen</h3>
              <p className="font-weight-500 font-size-lg text-shadow">Die Qualität eines Nahrungsergänzungsmittels hängt maßgeblich von den eingesetzten Rohstoffen ab. Aus diesem Grund achten wir bei der Beschaffung nicht nur auf Reinheit und Wirksamkeit, sondern auch auf nachhaltige und faire Produktionsbedingungen. Unsere Expertise ermöglicht es uns, sowohl klassische Wirkstoffe wie Vitamine und Mineralien als auch Trendinhaltsstoffe wie Adaptogene oder Superfoods zu verarbeiten. Jede Rezeptur wird individuell entwickelt, um Synergieeffekte zu maximieren und den bestmöglichen Mehrwert für Ihre Endkunden zu bieten.<br/>Darüber hinaus setzen wir auf enge Zusammenarbeit mit vertrauenswürdigen Lieferanten, die strengen Qualitäts- und Sicherheitsstandards unterliegen. Regelmäßige Prüfungen und Zertifizierungen stellen sicher, dass wir jederzeit gleichbleibende Ergebnisse erzielen und Ihr Produkt stets in erstklassiger Qualität liefern können. So entstehen hochwertige Nahrungsergänzungsmittel, die Ihren Kunden ein Höchstmaß an Wirksamkeit und Vertrauenswürdigkeit garantieren.</p>
            </div>
          </div>
        </div>
        <section className=" bg-white py-10" style={{ marginTop: 100, marginBottom: 100 }}>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-7 last-paragraph-no-margin sm-margin-30px-bottom">
                <h5 className="alt-font font-weight-600 text-extra-dark-gray">Innovativ und sicher verpackt.<br/>Unsere vielfältigen Verpackungsoptionen.</h5>
                <p className="font-size-lg text-black">Die Wahl der richtigen Verpackung ist entscheidend, um Ihr Produkt optimal zu schützen und zugleich attraktiv zu präsentieren. Wir bieten eine große Bandbreite an Möglichkeiten, angefangen bei klassischen Dosen und Blistern über Sachets, Stick Packs und Gläser bis hin zu umweltfreundlichen Alternativen. Gemeinsam finden wir das Format, das Ihre Inhaltsstoffe bestmöglich bewahrt und gleichzeitig den Bedürfnissen Ihrer Zielgruppe gerecht wird.

                  Ob kleine Probiersets oder groß angelegte Verkaufsverpackungen, wir entwickeln Lösungen, die Funktionalität und Design vereinen. Auch für anspruchsvolle Anforderungen, etwa bei licht- oder sauerstoffempfindlichen Wirkstoffen, bieten wir passende Verpackungslösungen. So schaffen wir ein stimmiges Gesamterlebnis, bei dem sich Qualität nicht nur im Inhalt, sondern auch in der Hülle widerspiegelt.</p>
              </div>
              <div className="col-12 col-lg-4 offset-lg-1 col-md-5">
                <img src="images/bottle.png"/>
              </div>
            </div>
          </div>
        </section>



        <span className="scroll-top-arrow">
          <i className="feather icon-feather-arrow-up text-extra-dark-gray" />
        </span>
      </React.Fragment>
    );
}


export default withTranslation("general")(Portfolio);
