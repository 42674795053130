import * as React from "react";
import { useState } from "react";
import { Image, Nav } from "react-bootstrap";
import SimpleConfirmationModal from "../common/SimpleConfirmationModal";

interface TabsProps {
  activeTab: string;
  onTabChange: any;
  t: (key: string, options?: any) => string;
}

interface TabProps extends TabsProps {
  name: string;
  showModal: boolean;
  onShowModal: any;
  svg: string;
}

export const CAPSULES_TAB = "capsules";
export const TABLETS_TAB = "tablets";
export const POWDER_TAB = "powder";
export const LIQUID_TAB = "liquid";
export const SOFTGELS_TAB = "softgels";
export const CUSTOM_TAB = "custom";

// Name of the tabs and their icon
const TabsDefinition = {
  capsules: "/media/products/kapsel-weiss.png",
  tablets: "/media/products/tablette-weiss.png",
  powder: "/media/products/pulver-weiss.png",
  liquid: "/media/products/liquid.png",
  softgels: "/media/products/softgel.png",

};

const ConfiguratorTab: React.FunctionComponent<TabProps> = React.memo(
  ({ activeTab, onTabChange, name, onShowModal, showModal, svg, t }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
      if (name !== activeTab) setShow(true);
    };
    const handleTabChange = () => {
      if (name !== activeTab) onTabChange(name);
    };
    return (
      <>
        <Nav.Link
          className="nav-link py-lg-10 d-flex flex-grow-1 rounded flex-md-column flex-row configurator-tab mt-5"
          id={name}
          eventKey={name}
          onClick={showModal ? handleShow : handleTabChange}
          active={activeTab === name}
        >
          <div className="d-md-flex flex-grow-1 flex-md-column text-center">
            <span className="nav-icon py-md-2 w-auto pr-2 pr-md-0 align-middle">
              <span className="symbol symbol-40 symbol-md-60">
                <Image src={svg} />
              </span>
            </span>
            <span className="nav-text font-size-lg py-md-2 font-weight-bold">{t(name)}</span>
          </div>
        </Nav.Link>
        {showModal && (
          <SimpleConfirmationModal.SimpleConfirmationModal
            show={show}
            modalTitle={t("tabChange")}
            modalDescription={t("tabChangeInfo")}
            confirmButtonText={"Ok"}
            cancelButtonText={t("cancel")}
            onConfirm={() => onTabChange(name)}
            showModal={showModal}
            onShowModal={onShowModal}
            onClose={handleClose}
            checkboxText={t("dontShow")}
          />
        )}
      </>
    );
  }
);

const ConfiguratorTabs: React.FunctionComponent<TabsProps> = React.memo(({ activeTab, onTabChange, t }) => {
  const [showModal, setShowModal] = useState(true);
  return (
    <ul
      id="configuratorTabs"
      className="dashboard-tabs nav nav-secondary nav-pills row row-paddingless m-0 p-0 flex-column flex-md-row"
      role="tablist"
    >
      {Object.entries(TabsDefinition).map(([key, value]) => {
        return (
          <li className={"nav-item col mb-3 mb-lg-0" + (key === CUSTOM_TAB ? " mr-0" : " mr-3")} key={key}>
            <ConfiguratorTab
              name={key}
              svg={value}
              showModal={showModal}
              onShowModal={setShowModal}
              activeTab={activeTab}
              onTabChange={onTabChange}
              t={t}
            />
          </li>
        );
      })}
    </ul>
  );
});

export default ConfiguratorTabs;
