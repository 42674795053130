import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { getTopSpaceHeaderHeight } from "../assets/js/topSpaceHeaderHeight";

class HeroImage extends Component {
  state = {};

  render() {
    const { t } = this.props;
    return (
      <section className="big-section cover-background herovideo" style={{marginTop: getTopSpaceHeaderHeight()}}>
        <div className="opacity-extra-medium-2" style={{ opacity: 0.6, backgroundColor: "#000011" }} />
        <div className="container">
          <div className="row justify-content-center pt-0 pb-0">
            <div className="col-12 col-xl-12 col-lg-12 col-md-12 overlap-gap-section pt-5 pb-5">
              <h4
                className="alt-font text-white font-weight-600  wow animate__fadeIn  mb-5 mobile"
                style={{ visibility: "visible", animationName: "fadeIn" }}
              >
               {t("update1")}
              </h4>
              <h2
                  className="alt-font text-white font-weight-600  wow animate__fadeIn mb-5 desktop"
                  style={{ visibility: "visible", animationName: "fadeIn" }}
              >
                {t("update1")}
              </h2>{" "}
              <h6
                className="text-white alt-font  letter-spacing-1px wow animate__fadeIn w-70 w-sm-100x"
                style={{ visibility: "visible", animationName: "fadeIn" }}
              >
                {t("update2")}
              </h6>
              <h6
                className="text-white alt-font  letter-spacing-1px wow animate__fadeIn w-70 w-sm-100x"
                style={{ visibility: "visible", animationName: "fadeIn" }}
              ></h6>
              <h6
                className="text-white alt-font  letter-spacing-1px wow animate__fadeIn w-70 w-sm-100x"
                style={{ visibility: "visible", animationName: "fadeIn" }}
              >
                {t("update3")} <b>ISO 9001</b>, <b>IFS Food</b>, <b>FDA</b>, <b>{t("update56")}</b>  {t("update4")}{" "}
                <b>FSSC 22000</b>.
              </h6>
              {/*<h6
                className="text-white alt-font  letter-spacing-1px wow animate__fadeIn w-70 mt-20"
                style={{ visibility: "visible", animationName: "fadeIn" }}
            ><i className="flaticon flaticon2-accept text-white mr-2"/>Kapseln<i className="flaticon flaticon2-accept text-white mr-2 ml-5"/>Tabletten<i className="flaticon flaticon2-accept text-white mr-2 ml-5"/>Softgels<i className="flaticon flaticon2-accept text-white mr-2 ml-5"/>Pulver</h6>*/}
            </div>
          </div>
        </div>
        <video loop="true" autoplay="" controls="" muted className="html-video2 " id="video-10">
          <source
            type="video/mp4"
            src="images/bgvideo-2.mp4"
            /*  src="https://private-label-factory.de/video/plf-img-neu.mp4" */
          />
        </video>
      </section>
    );
  }
}

export default withTranslation("general")(HeroImage);
