import React, { Component } from "react";
import Cert from "../sections/cert";
import { withTranslation } from "react-i18next";
import Bubbles from "../sections/bubbles";
import { Tilt } from "react-tilt";
import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";
import { getTopSpaceHeaderHeight } from "../assets/js/topSpaceHeaderHeight";

class Certifications extends Component {
  render() {
    const { t } = this.props;
    const items = ["ISO 22000", "IFS", "FDA", "Organic", "HACCP", "GMP"];
    const extendedItems = [...items, ...items, ...items];

    return (
      <React.Fragment>
        <div className="bubbles-container" style={{marginTop: getTopSpaceHeaderHeight()-5}}>
          <section className="pt-0 h-50vh bb" id="quality">
            {" "}
            <div
              className="opacity-extra-medium-2 bg-dark-slate-blue"
              style={{ opacity: 0.2, backgroundColor: "#151b2a" }}
            />
            <div className="container absolutely-centered sm-mt-0" style={{ marginTop: 200 }}>
              <div className="row justify-content-center pt-0 pb-0">
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 overlap-gap-section pt-5 pb-5">
                  <h1
                    className="alt-font text-white font-weight-600  wow animate__fadeIn  mb-3 mobile-font-smaller-2"
                    style={{ visibility: "visible", animationName: "fadeIn" }}
                  >
                    {t("update42")}
                  </h1>{" "}
                  <h6
                    className="text-white alt-font  letter-spacing-1px wow animate__fadeIn w-70 sm-w-100"
                    style={{ visibility: "visible", animationName: "fadeIn" }}
                  >
                    {t("update43")}{" "}
                    <b> {t("update44")}</b>
                  </h6>
                  <h6
                    className="text-white alt-font  letter-spacing-1px wow animate__fadeIn w-70"
                    style={{ visibility: "visible", animationName: "fadeIn" }}
                  />{" "}
                </div>
              </div>
            </div>
            <Bubbles />
          </section>
        </div>

        <section className="bg-light-gray">
          <div className="container" style={{ paddingTop: 100 }}>
            <div className="row align-items-center justify-content-center">
              <div
                className="col-12 col-lg-12 col-md-12 md-margin-7-rem-bottom wow animate__fadeInLeft"
                data-wow-delay="0.5s"
                style={{ visibility: "visible", animationDelay: "0.5s", animationName: "fadeInLeft" }}
              >
                <h5 className="alt-font font-weight-600 text-extra-dark-gray w-95">
                  {t("update45")}
                  <br />
                  {t("update46")}
                  <br />
                  <br />
                  {t("update47")}
                </h5>
              </div>
            </div>
          </div>
        </section>
        {/* #################### */}
        <section
          className="wow animate__fadeIn rmb-767 am5b-767"
          style={{ visibility: "visible", animationName: "fadeIn" }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-xl-6 col-lg-5 col-md-10 md-margin-5-rem-bottom">
                <div className="position-relative w-95 md-w-100">
                  <img className="border-radius-10px" src="images/qm-picture.jpg" alt="" data-no-retina />
                </div>
              </div>
              <div className="col-12 col-xl-5 col-lg-6 offset-lg-1 text-lg-start">
                <h3 className="alt-font font-weight-600 text-extra-dark-gray"> {t("update48")}</h3>
                <p className="font-size-lg text-black">
                  {t("update49")}
                </p>{" "}
                <a
                  href="mailto:info@private-label-factory.com?subject=Request:%20Certifications&body=Dear%20Sir%20or%20Madam,%0A%0AI%20am%20contacting%20you%20to%20request%20the%20current%20certificates%20for%20your%20certifications.%20Please%20send%20me%20the%20relevant%20documents%20at%20your%20earliest%20convenience.%0A%0AThank%20you%20for%20your%20assistance.%0A%0ABest%20regards,%0A[Your%20Name]

"
                  className="btn btn-large btn-dark-gray margin-35px-right sm-margin-15px-right"
                >
                  {t("update50")}
                </a>
              </div>
            </div>{" "}
            <div className="horizontal-distribution  py-5 mt-5 text-white border-radius-10px">
              <div className="marquee-container mt-2">
                <div className="custom-marquee">
                  {extendedItems.map((item, index) => (
                    <h5
                      key={index}
                      className="custom-marquee-item alt-font font-weight-600 text-extra-dark-gray "
                      style={{ lineHeight: 0 }}
                    >
                      {item}
                    </h5>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ################### */}

        <section className=" bg-white py-10">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-7 last-paragraph-no-margin sm-margin-30px-bottom">
                <h5 className="alt-font font-weight-600 text-extra-dark-gray">
                  {t("update51")}
                </h5>
                <p className="font-size-lg text-black">
                  {t("update52")}
                </p>
              </div>
              <div className="col-12 col-lg-4 offset-lg-1 col-md-5">
                <Tilt className="Tilt" options={{ max: 25, scale: 1.0 }}>
                  <div className="border-radius-10px align-items-start h-100 justify-content-center d-flex flex-column padding-4-half-rem-all  background-no-repeat background-position-right-bottom lg-padding-3-rem-all bg-light-gray">
                    <i className="fas fa-quote-left text-extra-dark-gray icon-extra-medium margin-30px-bottom" />
                    <h6 className="text-extra-dark-gray letter-spacing-minus-1 w-100">
                      {t("update53")}
                    </h6>
                    <span className="alt-font text-uppercase text-medium text-black">Fabian Geisler</span>
                  </div>
                </Tilt>
              </div>
            </div>
          </div>
        </section>

        <div className="text-white" style={{ marginTop: 100 }}>
          <div className="container container2 cover-background border-radius-10px padding-5-half-rem-all">
            <video loop="true" autoPlay="true" controls="" muted className="html-video2 ">
              <source type="video/mp4" src="images/forest.mp4" />
            </video>

            <div className="row overlap-gap-section border-radius-10pxl">
              <h3 className="alt-font font-weight-600"> {t("update54")}</h3>
              <p className="font-weight-500 font-size-lg text-shadow">
                {t("update55")}
              </p>
            </div>
          </div>
        </div>
        <section
          className="wow animate__fadeIn rmpb-767 am5b-767 pb-0 "
          style={{ visibility: "visible", animationName: "fadeIn" }}
        >
          <div className="container mt-5">
            <div className="row align-items-center justify-content-center">
              <div className="col-12 col-xl-6 col-lg-6 col-md-6 d-flex justify-content-end lg-padding-40px-left md-margin-50px-bottom dnb-400">
                <figure className="position-relative m-0">
                  <img src="images/pierre.png" alt="" />
                </figure>
              </div>
              <div className="col-12 col-xl-6 col-lg-6 col-md-6">
                <h3 className="alt-font font-weight-600 text-extra-dark-gray">{t("title4")}</h3>
                <p className="text-black">{t("text3")}</p>
              </div>
            </div>
          </div>
        </section>
        <span className="scroll-top-arrow">
          <i className="feather icon-feather-arrow-up text-extra-dark-gray" />
        </span>
      </React.Fragment>
    );
  }
}

export default withTranslation("general")(Certifications);
