import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import { getTopSpaceHeaderHeight } from "../assets/js/topSpaceHeaderHeight";

class Privacy extends Component {
  state = {};
  render() {
    return (
      <section>
        <div className="container"  style={{marginTop: getTopSpaceHeaderHeight()}}>
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-lg-12 col-md-12 md-margin-7-rem-bottom">
              <h5 className="alt-font font-weight-600 text-extra-dark-gray w-95">
                Datenschutzerklärung
                <br />
              </h5>
              <span className="text-extra-dark-gray text-large">
                <br />
                <br />
                <h6>Allgemeine Hinweise</h6>{" "}
                <p>
                  Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
                  passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
                  persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie
                  unserer unter diesem Text aufgeführten Datenschutzerklärung.
                </p>
                <h6>Datenerfassung auf unserer Website</h6>{" "}
                <p>
                  <strong>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</strong>
                </p>{" "}
                <p>
                  Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten
                  können Sie dem Impressum dieser Website entnehmen.
                </p>{" "}
                <p>
                  <strong>Wie erfassen wir Ihre Daten?</strong>
                </p>{" "}
                <p>
                  Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B.
                  um Daten handeln, die Sie in ein Kontaktformular eingeben.
                </p>{" "}
                <p>
                  Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor
                  allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
                  Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website betreten.
                </p>{" "}
                <p>
                  <strong>Wofür nutzen wir Ihre Daten?</strong>
                </p>{" "}
                <p>
                  Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten.
                  Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.
                </p>{" "}
                <p>
                  <strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong>
                </p>{" "}
                <p>
                  Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
                  gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung,
                  Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema
                  Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des
                  Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
                </p>
                <h6>Analyse-Tools und Tools von Drittanbietern</h6>{" "}
                <p>
                  Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor
                  allem mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens erfolgt in
                  der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden. Sie können dieser
                  Analyse widersprechen oder sie durch die Nichtbenutzung bestimmter Tools verhindern. Detaillierte
                  Informationen dazu finden Sie in der folgenden Datenschutzerklärung.
                </p>{" "}
                <p>
                  Sie können dieser Analyse widersprechen. Über die Widerspruchsmöglichkeiten werden wir Sie in dieser
                  Datenschutzerklärung informieren.
                </p>
                <h5>2. Allgemeine Hinweise und Pflichtinformationen</h5>
                <h6>Datenschutz</h6>{" "}
                <p>
                  Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
                  personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie
                  dieser Datenschutzerklärung.
                </p>{" "}
                <p>
                  Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene
                  Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende
                  Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch,
                  wie und zu welchem Zweck das geschieht.
                </p>{" "}
                <p>
                  Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
                  Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
                  nicht möglich.
                </p>
                <h6>Hinweis zur verantwortlichen Stelle</h6>{" "}
                <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>{" "}
                <p>
                  {" "}
                  Private Label Factory Deutschland GmbH
                  <br />
           Philipp-Reis-Straße 7
                  <br />
                 45659 Recklinghausen
                </p>
                <p>
                  Telefon: 0162 - 75 66 197
                  <br />
                  E-Mail: info@vprivate-label-factory.com
                </p>
                <p>
                  Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit
                  anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen,
                  E-Mail-Adressen o. Ä.) entscheidet.
                </p>
                <h6>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h6>{" "}
                <p>
                  Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können
                  eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per
                  E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
                  unberührt.
                </p>
                <h6>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h6>{" "}
                <p>
                  Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen
                  Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der
                  Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat. Eine Liste
                  der Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link entnommen werden:{" "}
                  <a
                    href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                    target="_blank"
                  >
                    https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
                  </a>
                  .
                </p>
                <h6>Recht auf Datenübertragbarkeit</h6>{" "}
                <p>
                  Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
                  automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
                  aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
                  verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                </p>
                <h6>Auskunft, Sperrung, Löschung</h6>{" "}
                <p>
                  Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche
                  Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck
                  der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu
                  sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im
                  Impressum angegebenen Adresse an uns wenden.
                </p>
                <h6>Widerspruch gegen Werbe-Mails</h6>{" "}
                <p>
                  Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von
                  nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die
                  Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten
                  Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.
                </p>
                <h5>3. Datenerfassung auf unserer Website</h5>
                <h6>Server-Log-Dateien</h6>{" "}
                <p>
                  Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
                  Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
                </p>{" "}
                <ul className="pl-4">
                  {" "}
                  <li>Browsertyp und Browserversion</li> <li>verwendetes Betriebssystem</li> <li>Referrer URL</li>{" "}
                  <li>Hostname des zugreifenden Rechners</li> <li>Uhrzeit der Serveranfrage</li> <li>IP-Adresse</li>{" "}
                </ul>{" "}
                <br />
                <p>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p>{" "}
                <p>
                  Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
                  Webseitenbetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der
                  Optimierung seiner Webseite – hierzu müssen die Server-Log-Files erfasst werden.
                </p>
                <h5>4. Datenschutzbeauftragter</h5>
                <p>
                  Unseren Datenschutzbeauftragten erreichen Sie unter der Email info@private-label-factory.com oder
                  unserer Postadresse mit dem Zusatz „der Datenschutzbeauftragte“.
                </p>
                <p> </p>
              </span>{" "}
              <ReactSVG src="images/logo-dark.svg" style={{ width: "160px" }} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Privacy;
